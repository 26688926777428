import styled from "styled-components";
import { Theme } from "./Theme";

export const LegalsWrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-color: ${Theme.colors.blue};
  padding-top: 10rem;
  padding-bottom: 10rem;
  position: relative;
`;

export const LegalsInside = styled.div`
  width: 90%;
  margin: 0 auto;
  color: #fff;
  white-space: pre-wrap;

  .title {
    font-size: 50px;
    font-style: italic;
    font-weight: 900;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
  h3 {
    font-weight: 700;
    margin-bottom: 18px;
    margin-top: 80px;
  }
  p {
    margin-bottom: 20px;
  }
`;

export const LegalsReturn = styled.p`
  font-size: 20px;
  font-weight: 900;
  position: absolute;
  top: 50px;
  right: 50px;
  opacity: 0.5;
  color: #fff;

  &:hover {
    opacity: 1;
  }
`;
