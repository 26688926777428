import React, { useState, useEffect } from "react";
import Layout from "@Components/Layout";
import SEO from "@Components/SEO";
import { Container, Row } from "react-bootstrap";
import { LegalsWrapper, LegalsInside, LegalsReturn } from "@Styles/legals";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { useStaticQuery, graphql, Link } from "gatsby";

export default function Legals() {
  let legals = useStaticQuery(graphql`
    query Legals {
      contentfulConfig(configuration: { eq: "Site configuration" }) {
        legals {
          childMdx {
            body
          }
        }
      }
    }
  `);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window?.innerWidth < 1024) {
      setIsMobile(true);
    }
  }, []);

  return (
    <Layout
      menuOpen={true}
      menuVisible={false}
      location={"legals"}
      textIsRed={false}
    >
      <SEO title="Legals" />
      <LegalsWrapper>
        <Link to={isMobile ? `/` : "/contact"}>
          <LegalsReturn>Retour</LegalsReturn>
        </Link>
        <Container>
          <Row>
            <LegalsInside>
              <p className="title">Mentions Légales</p>
              <MDXRenderer>
                {legals.contentfulConfig.legals.childMdx.body}
              </MDXRenderer>
            </LegalsInside>
          </Row>
        </Container>
      </LegalsWrapper>
    </Layout>
  );
}
